loadStimulus(document);

application.register("lib", class extends Stimulus.Controller {
    ripple(e) {
        LibRipple(e.currentTarget, e)
    }

    anchor(e) {
        e.preventDefault()
        LibAnchor.action(e.currentTarget)
    }

    goBack(e) {
        e.preventDefault()
        history.back()
    }
});
