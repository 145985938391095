application.register("lib-tabs", class extends Stimulus.Controller {
    connect() {
        let element = this.element;

        [...element.querySelector(`[data-lib-tabs-nav]`).querySelectorAll(`[data-lib-tabs-item="nav"]`)].forEach((selector, index) => {
            selector.addEventListener("click", (e) => {
                e.preventDefault();

                [...selector.closest(`[data-lib-tabs-nav]`).querySelectorAll(`[data-lib-tabs-item="nav"]`)].forEach((elm) => elm.classList.remove("state--active"));
                selector.classList.add("state--active");

                [...element.querySelector(`[data-lib-tabs-area]`).children].forEach((elm) => {
                    elm.classList.remove("state--active");
                });

                element.querySelector(`[data-lib-tabs-area]`).children[index].classList.add("state--active");
            })
        });
    }
});