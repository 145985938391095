application.register("lib-tippy", class extends Stimulus.Controller {
    connect() {
        if (importScript.used.indexOf(cdnjs.tippy) === -1) {
            setTimeout(() => {
                importScript([cdnjs.popper, cdnjs.tippy], () => {
                    this.generateTippy(this.element, this.data.get("options"));
                });
            }, 3000);
        } else {
            this.generateTippy(this.element, this.data.get("options"));
        }
    }

    generateTippy(element, options) {
        if(options === null) {
            options = "";
        }

        let data = options.replace(/\s/g, "").split(",");
        let content;
        let trigger = "mouseenter focus";
        let placement = "top";
        let arrow = true;
        let interactive = true;

        if (typeof data[1] !== "undefined") {
            if (document.getElementById(data[1]) !== null) {
                content = document.getElementById(data[1]).innerHTML;
            }
        } else {
            let parser = new DOMParser();
            content = parser.parseFromString(`
                <div class="part_ui_dropdown">
                    <div class="wrp_ui_body">
                        ${element.getAttribute("aria-label")}
                    </div>
                </div>
            `, "text/html").querySelector(".part_ui_dropdown");
            interactive = false;
        }

        if (typeof data[0] !== "undefined") {
            if (data[0].includes("dropdown")) {
                trigger = "click";
                if (data[0].includes("dropdown-")) {
                    placement = data[0].replace("dropdown-", "").replace(/\d+/, "");
                }
            } else if (data[0].includes("tooltip")) {
                if (data[0].includes("tooltip-")) {
                    placement = data[0].replace("tooltip-", "");
                    arrow = true;
                    interactive = false;
                }
            } else {
                placement = data[0];
            }
        }

        tippy(element, {
            content: content,
            placement: placement,
            theme: 'light-border',
            trigger: trigger,
            interactive: interactive,
            arrow: arrow,
            offset: [0, -8],
            animation: "scale",
            inertia: true,
            allowHTML: true,
            appendTo: document.body
        });
    }
});