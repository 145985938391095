application.register("lib-pdf", class extends Stimulus.Controller {
    static get values() {
        return {
            options: Object
        }
    }

    connect() {
        const self = this;

        inView(self.element, () => {
            importScript(cdnjs.pdfjs, () => {
                const pdfjsLib = window['pdfjs-dist/build/pdf'];

                pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.10.377/build/pdf.worker.min.js';

                const loadingTask = pdfjsLib.getDocument(self.getValue('options').src);
                loadingTask.promise.then(function(pdf) {
                    const pageNumber = 1;
                    pdf.getPage(pageNumber).then(function(page) {
                        const scale = 1.5;
                        const viewport = page.getViewport({scale: scale});

                        const canvas = self.getTarget('canvas');
                        const context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };
                        const renderTask = page.render(renderContext);
                        renderTask.promise.then(function () {

                        });
                    });
                }, function (reason) {
                    console.error(reason);
                });
            })
        })
    }
});
